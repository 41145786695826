.centered-content {
  // down to 2 wide on the filters page
  &.with-filters {
    @media only screen and (max-width: 1050px) {
      width: 440px;
    }

    @media only screen and (min-width: 1050px) {
      width: 648px;
    }

    @media only screen and (min-width: 1350px) {
      width: 872px;
    }

    @media only screen and (min-width: 1700px) {
      width: 1096px;
    }
  }

  // minimum 3 wide on all other feed pages
  &.without-filters {
    @media only screen and (max-width: 1350px) {
      width: 648px;
    }

    @media only screen and (min-width: 1350px) {
      width: 872px;
    }

    @media only screen and (min-width: 1700px) {
      width: 1096px;
    }
  }

  margin: auto !important;
}

.no-assets-h2 {
  display: flex;
  flex-direction: row;
  width: 280px;
  word-wrap: normal;
  justify-items: right;
}

.C-Button.smallText {
  & > span > span {
    font-size: 11px;
  }
}

.no-assets-image {
  background-image: url('./noAssetsImage.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px 60px;
  background-color: #fcfcfc;
  min-height: 80px;
}

.empty-collection-links {
  text-decoration: underline;
}


.placeholder-item {
  width: 100%;
}

.bottom-logo {
  height: 103px;
  width: 175px !important;
  background-image: url('./bottom-logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-self:center;
}

.asset-actions-dropdown {
  max-width:180px;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .margin-fix-safari {
      margin: 0 !important;
    }

    .padding-fix-safari {
      padding: 0 !important;
    }
  }
}

.search-asset-list-container {
  height: 75vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.search-asset-list-container::-webkit-scrollbar {
  display: none;
}

.container-width-control {
  @media only screen and (min-width: 1500px) {
    width: 100%;
    padding: 0 calc((100% - 1150px) / 2);
  }
  @media only screen and (min-width: 1301px) and (max-width: 1499px) {
    width: 100%;
    padding: 0 calc((100% - 900px) / 2);
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
    padding: 0 calc(100% / 8);
  }
}
