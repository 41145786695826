.searchBarContainer {
  margin: 1px !important;
  @media only screen and (max-width: 1049px) {
    width: 440px !important;
  }

  @media only screen and (min-width: 1050px) {
    width: 648px !important;
  }

  @media only screen and (min-width: 1350px) {
    width: 872px !important;
  }

  @media only screen and (min-width: 1700px) {
    width: 1100px !important;
  }


  .searchToolToggle {
    display: inline-flex;
  }

  .searchToolTip {
    padding-left: 5px;
  }

  .C-FormField--Select {
    .C-FormField--Select__Background {
      background-color: transparent;
    }
  }

  &.search-page-search {
    .C-FormField--Toggle{
      color: black !important;
    }
  }
  .searchToolsContainer {
    padding-top: 0 !important;

    .C-Tooltip:before{
      background-color: #333;
      color: white;
      border: none;
      text-align: center;
    }
    .searchTools {
      padding: 0 !important;

      & > div {
        padding-top: 0px !important;
      }
      .exactSearch {
        display:inline-flex;
        padding-left: 0;
        position: relative;
        align-items: center;
        .C-FormField--Toggle{
          color: white;
          width: 200px;
          white-space: break-spaces;
        }
      }
      .listSearch {
        padding-right: 0;
        color: white;
        &Link {
          font-weight: bold;
          color:#81A1E4;
          text-decoration: none;
        }
      }
    }
  }
}

#header-search-bar {
  // width: 50% !important;
  // margin-right: -100px !important;
}

.search {
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 16px;
  padding: 8px;
  padding-left: 20px;
  // remove chrome's default outline before applying our own on focus
  outline: none;
  width: 100%;
}

.modal-search-bar {
  width: 630px !important;
}

.searchTaxonomySelect {
  width: auto;
  display:flex;
   select {
    height: 36px;
  }
}

.searchInput {
  position: relative;
  height: 100%;
  form {
    position: relative;
    display:grid;
    .search-icon {
      position: absolute;
      justify-self:flex-end;
      align-self:center;
      align-content:center;
      justify-content: center;
      z-index: 2;
      padding-right: 8px;

      :hover {
        cursor: pointer;
      }
    }
  }
  ::placeholder {
    font-size: 16px !important;
    color: #7d7d7d;
  }

  :-ms-input-placeholder {
    font-size: 16px !important;
    color: #7d7d7d;
  }

  ::-ms-input-placeholder {
    font-size: 16px !important;
    color: #7d7d7d;
  }
}

.hero-search {
  // 1440 is width of container and 642 width of search bar in desired circumstances
  min-width: 440px;
  margin-left: 1px;
  flex-grow: 1;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.searchTaxonomyContainer{
  padding: 0 !important;
  display:flex;
  justify-content: center;
  align-items: center;
}

.search-page-search {
  flex-grow: 1;
  min-width: 500px;
  padding-left: 2px !important;
}

.header-search {
  max-width: 255px !important;
  // take up the entirety of grid item minus the help buttons
}

.disable-search {
  pointer-events: none;
  opacity: 30%;
}

.C-FormField--Toggle__input:focus + .C-FormField--Toggle__slider {
  box-shadow: none !important;
  color: white;
}
