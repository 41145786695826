.result {
  display: flex;
  flex: 1;
  max-width: 400px;
}
.filters {
  // filter sidebar maxes out at 300px
  max-width: 300px !important;
  display: flex;
  flex-direction: column;
}
.centered-content {
  // down to 2 wide on the filters page
  &.with-filters {
    @media only screen and (max-width: 1050px) {
      width: 440px;
    }

    @media only screen and (min-width: 1050px) {
      width: 648px;
    }

    @media only screen and (min-width: 1350px) {
      width: 872px;
    }

    @media only screen and (min-width: 1700px) {
      width: 1096px;
    }
  }

  margin: auto !important;
}
.gallery-chips-dense {
  height: 24px;
  margin-bottom: 3px;
  margin-top: 3px;
}

.gallery-chips-dense span {
  padding-left: 4px;
  padding-right: 4px;
}

.container-width-control {
  @media only screen and (min-width: 1300px) {
    width: 100%;
    padding: 0 calc((100% - 1100px) / 2);
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
    padding: 0 calc(100% / 10);
  }
}
