.filter-checkbox {
  // helper classes arent fine enough for checkbox styling
  margin: 1px 0px 2px 2px !important;
  padding: 1px 0px 3px 2px !important;
  // white-space: nowrap;
}

.filter-avatar {
  height: 20px;
  width: 20px;
  font-size: 10px;
}

.checkbox-container {
  height: auto;
  max-height: 130px;
}

.hc-pr-normal{
  padding-right: 0px!important;
}

.datepicker-filter-drawer{
  margin-right: 5px!important;
    .C-GridItem{
      padding-left: 18px!important;
    }
    .C-Button {
      svg{
        color: black!important;
      }
    }
    .C-ExpandableSection__TopRow{
      padding: 12px 12px 0px 12px !important;
    }
    .C-ExpandableSection__ContentContainer {
      .C-GridItem{
        padding-bottom: 5px !important;
        padding-left: 0px !important;
      }
    }
  }

.filter-drawer{
  margin-right: 5px!important;
    .C-GridItem{
      padding-right: 16px!important;
      padding-left: 18px!important;
    }
    .C-Button {
      svg{
        color: black!important;
      }
    }
    .C-ExpandableSection__TopRow{
      padding: 12px 12px 0px 12px !important;
    }
    .C-ExpandableSection__ContentContainer {
      .C-GridItem{
        padding-bottom: 5px !important;
        padding-left: 0px !important;
      }
    }
  }

.filter-category-title{
  color: #666666!important;
  font-weight: bold!important;
  margin-left: -5px!important;
}

