button.C-Breadcrumbs__crumb {
    border:none;
    background: transparent;
    letter-spacing: 1px;
    cursor: pointer;
}

.C-Breadcrumbs__crumb {
    font-weight: bold;
    text-transform: uppercase;
}

.C-Layout__backgroundCover {
    background-color: var(--canvas-helper-color--contrast-weak)
}

.main-content {
    // main content should height of the screen minus the bread crumbs and header
    min-height: calc(100vh - 111px);
    position:relative;
}