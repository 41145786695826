/*** GLOBAL SCROLLBAR STYLES ***/
/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.C-Toaster {
  z-index: 999999999;
}
//Adds padding to errorinfo for formfields
.C-FormField__FieldInfo {
  margin-top: 6px;
}
.C-Tooltip:before {
  background-color: white;
  color: #333;
  border: 1px solid rgb(209, 208, 208);
}
