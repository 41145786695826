span.bold {
    font-weight: bold;
    margin-right:8px;
}

.gray-border {
    border-left: thin solid darkgray;
    max-height: 2em;
}

.more-tab-arrow {
    position: absolute;
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
.C-Tabs__Tab {
    height:50px;
    margin-bottom:8px;
    cursor: pointer;
    transition-property: none;
}

.tabs__tab-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
        margin-bottom:2.5em;
    }
    svg {
        margin-bottom: 4px!important;
    }
}

.tabs__content {
    width:100%;
    &.hidden {
        visibility: hidden;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.3 !important;
}

.content-container {
    margin: auto!important;
    @media only screen and (max-width: 1200px) {
        max-width: 696px;
    }
    
    @media only screen and (min-width: 1200px) {
        max-width: 1344px;
    }
}

.tabs-selector :focus {
    background-color: #fcfcfc;
}

// .tabs-selector :hover {
//     background: none;
// }

.C-DropdownMenuItem__item:hover {
    background-color: #efefef;
}

.more-dropdown-menu {
    z-index: 5;
    right: -10% 
}