.subcategory-page-centered-content {
    // Temporary fix for our design needs. Will change w/ canvas at later date.
        @media only screen and (max-width: 1350px) {
            max-width: 648px!important;
        }
    
        @media only screen and (min-width: 1350px) {
            max-width: 872px!important;
        }
    
        @media only screen and (min-width: 1700px) {
            max-width: 1096px!important;
        }
        
        margin: auto !important;
    }