.add-tag-form {
    // position: relative;
    display:flex;
    .add-tag-button {
        // padding: inherit;
        // position:absolute;
        // right:10px;
        // z-index:2;
        min-width: 10%;
    }
    ::placeholder {
        font-size: 14px!important;
        color: #7d7d7d;
    }
    
    :-ms-input-placeholder {
        font-size: 14px!important;
        color: #7d7d7d;
    }
    
    ::-ms-input-placeholder {
        font-size: 14px!important;
        color: #7d7d7d;
    } 
}

.add-tag-input {
    border: 1px solid gray;
    font-size: 16px;
    padding:8px; 
    padding-left: 20px;
    // remove chrome's default outline before applying our own on focus
    outline: none;
}