.edit-collection-user-modal {

   .C-Modal__scrollContainer{
        max-width: 40em;
        min-width: 40em ;
        min-height: 40em;
        max-height: 40em;
        display: flex;
        flex-direction: column;
        outline: none;
     }

     .C-Modal__headerContainer{
        font-size: 24px;
        padding: 24px 24px 0 24px;
     }

     .C-Modal__container {
         margin: 0;
         outline: none;
         border-radius: 16px;
     }
    
    .edit-collection-user-container {
        display: flex;
        flex-grow: 1;
        outline: none;
    }

    .C-Form {
        flex-grow: 1;
        outline: none;
    }

    .C-FormField__Label {
        font-weight: normal;
            line-height: 18px;
            margin-left: 4px;

    }

    .bottom-buttons{
        outline: none;
        margin-bottom: 12px;
    }

    .cancel-button{
        background: transparent;
        border: transparent;
        margin-right: 20px;
        font-weight: normal;
    }

    .save-button{
        margin-right:22.75px;
        border-radius: 8px;
    }

    .collection-user-list {
        max-height: 20em;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        outline: none;
    }

    .collection-user-item {
        min-height: 5em;
        outline: none;
    }

    // changes font size of placeholder form field text
    & ::placeholder {
        font-size: 14px !important;
        font-weight:lighter;
    }

    .spinner {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .owner-name{
        font-weight: 700
    }

    .manager-names{
        font-weight: 700
    }

    .C-Tooltip:before {
        padding: 8px;
    }

}