.collection-page-centered-content {
// Temporary fix for our design needs. Will change w/ canvas at later date.
    @media only screen and (max-width: 1350px) {
        width: 648px!important;
    }

    @media only screen and (min-width: 1350px) {
        width: 872px!important;
    }

    @media only screen and (min-width: 1700px) {
        width: 1096px!important;
    }
    
    margin: auto !important;
}

.collection-more-button {
    max-width: 36px;
}