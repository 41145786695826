.bulk-asset-upload-popup-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.bulk-asset-upload-popup-list {
  max-height: 60vh;
  overflow-y: auto;
}

.bulk-asset-upload-icon {
  height: 50px !important;
  color: #2f5ebc;
  cursor: pointer;
}

.bulk-asset-upload-info-card {
  background-color: #2f5ebc;
}

.bulk-asset-upload-popup-item {
  width: 100%;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

.spin {
  animation: spin-animation 2s linear infinite;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
