.slack-image {
    background-image: url('/../public/slack_icon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: #fcfcfc;
    min-height: 30px;
    min-width: 30px;
}

.help-button {
    width: 80%;
    border: 2px solid lightgray;
    min-height: 44px;
}