.galleryCard {
  width: 316px;
  border-radius: 0px !important;
  position: relative;

  .cardContainer {
    position: relative;

    .chip-container {
      position: relative;
      top: 30px !important;
      z-index: 6;

      .download-chip {
        padding-left: 8px !important;
      }


      .C-Chip {
        border: 0 !important;
        position: relative;
        top: 8px !important;
        z-index: 5;
        font-weight: bold;
        border-radius: 10px;
        width: 32px;
        height: 24px;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;

        .C-Chip__children {
          padding: 0 0px;
        }

        .enterprise-icon {
          position: relative;
          align-content: center;
        }
      }
    }


    // Empty Collection

    .emptyCollection {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
      border: #e6e6e6 solid 1px;
      grid-row: 1;
      grid-column: 1;
    }

    // 1 image layout

    .image-1.image-no-0 {
      grid-row: 1/3;
      grid-column: 1/3;
    }

    // 2 image layout
    .image-2.image-no-0 {
      grid-row: 1/3;
      grid-column: 1;
    }

    .image-2.image-no-1 {
      grid-row: 1/3;
      grid-column: 2;
    }

    // 3 image layout
    .image-3.image-no-0 {
      grid-row: 1/3;
      grid-column: 1/3;
    }

    .image-3.image-no-1 {
      grid-row: 1;
      grid-column: 3;
    }

    .image-3.image-no-2 {
      grid-row: 2;
      grid-column: 3;
    }

    .thumbnailSpinner {
      grid-column: 1;
      justify-self: start;
    }

    .thumbnailGallery {
      display: grid;

      &.gallery-hover {
        filter: brightness(80%);
        -webkit-filter: brightness(80%);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
      }

      &.layout-3 {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.layout-2 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.layout-1 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.layout-0 {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
      }

      grid-auto-rows: minmax(204px, auto);
      grid-column-gap: 4px;
      grid-row-gap: 4px;
      width: 308px !important;
      height: 204px !important;
    }
  }
}
