.hero-background {
    // negative margins to account for the padding of the main page, then add to width
    margin: -12px -12px 0px -12px;
    width: calc(100% + 24px);
    // 1440 x 260 is the dimensions of the hero image under ideal circumstances
    height: calc(100vw * (260/1440));
    max-height: 400px;
    background-image: url('./hero.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: flex;
    align-items: center;
    justify-content: center;
}
