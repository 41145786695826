.id-list-search {
  &-modal {
    .C-Modal__scrollContainer {
      max-width: 35vw;
      min-width: 500px !important;
      justify-self: center;
      align-self: center;
      .C-Modal__container {
        border-radius: 15px;
      }
    }
  }

  &-terms-input {
    textarea {
      min-height: 180px;
    }
  }
}
