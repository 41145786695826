.noCollections {
    min-height: 350px;
    display: flex;
    justify-content: center;
    align-content: center;

    p a {
        text-decoration: underline;
    }

    .noCollectionCard {
        justify-content:space-around;
    }
}