.filter-heading {
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    width: 100%;
}

.filter-container {
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    align-self:stretch;
    width: 100%;
    height: 71vh;
    overflow-y: scroll;    
}

.date-filter-container {
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    align-self:stretch;
    width: 100%;
}

.filter-container::-webkit-scrollbar {
    display: none;
}

.C-DatePicker__Card {
    width: max-content;
}

.C-DatePicker__MonthIcons {
    top: 22px !important;
}

// IMPLEMENTING STICKY FILTER BAR
// .sticky {
//     position: fixed;
//     top: 0;
//     left: 0;
//     box-shadow: 1px 1px 1px #222;
//     animation: moveDown 0.5s ease-in-out;
// }

.clear-filters-button {
    span {
        padding: 0px 8px 0px 8px!important;
    }
}