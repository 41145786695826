.asset-table-icons {
    text-align: right;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.icon-dimensions {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid lightgrey !important;
    margin: 0 8px 0 0;
}

.asset-icons-container {
    align-items: flex-end;
    display: flex;
}
