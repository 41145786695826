

.linked-asset-drawer {
    .C-Button {
        color: black
    }
}

.linked-asset-drawer-expandable-container {
    width: 100%;
    .C-ExpandableSection__Content {
        animation-duration: 0s !important;
    }
}


.drawer-label {
    font-weight: bold;
    color: #666;
    & span {
        font-weight:normal;
    }
}

.linked-asset-card {
    background-color: #f0f0f0;
    &:hover {
        background-color: #e6e6e6;
    }
}
.linked-asset-display {
    padding: 5px;
    cursor: pointer;

    & .asset-name {
        font-weight: bold;
    }

    & .asset-name-grid {
        padding: 0 !important;
    }
}

