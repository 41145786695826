.asset-table-container {
    padding: 0px 25px!important;
}

.asset-table-text {
    color: black;
    font-weight: bold;
}


.file-download-icon {
    position: relative;
    margin: 0 0 0 8px !important;
}

.fa-rotate-90 {
    -ms-transform:rotate(90deg);     /* Internet Explorer 9 */
    -webkit-transform:rotate(90deg); /* Chrome, Safari, Opera */
    transform:rotate(90deg);         /* Standard syntax */
}

.asset-table-icons {
    text-align: right;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.asset-icons-container {
    align-items: flex-end;
    display: flex;
}

.icon-dimensions {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid lightgrey !important;
    margin: 0 8px 0 0;
}
