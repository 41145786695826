.C-FormField--Select {
    .C-FormField--Select__Background {
      background-color: transparent;
    }
  }

.search {
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 16px;
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  // remove chrome's default outline before applying our own on focus
  outline: none;
  width: 100%;
}

.modal-search {
  border-radius: 10px;
  border: 1px solid gray;
  width: 100%;
  padding-left: 10px;
  outline: none;
  height: 30px !important;
  flex-grow: 1;
}

.searchInput {
  position: relative;
  max-width: 700px !important;
  width: 100%;
  form {
    position: relative;
    display:grid;
    .search-icon {
      position: absolute;
      justify-self:flex-end;
      align-self:center;
      align-content:center;
      justify-content: center;
      z-index: 2;
      margin: 4px 8px 0px 0px;
      :hover {
        cursor: pointer;
      }
    }
  }
  ::placeholder {
    font-size: 16px !important;
    color: #7d7d7d;
  }

  .selected-option {
    margin-right: 15px !important;
    padding-right: 15px !important;
}

  :-ms-input-placeholder {
    font-size: 16px !important;
    color: #7d7d7d;
  }

  ::-ms-input-placeholder {
    font-size: 16px !important;
    color: #7d7d7d;
  }
}

.disable-search {
  pointer-events: none;
  opacity: 30%;
}

.C-FormField--Toggle__input:focus + .C-FormField--Toggle__slider {
  box-shadow: none !important;
  color: white;
}
