.carousel-wrapper {
    position: relative;
    overflow: hidden;
}
.carousel-left {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 5;
}
.carousel-right {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 5;
}
.carousel-button {
    background: white;
    :hover {
        background: white;
        border-radius: 20px;
    }
}
.carousel {
    
    .carousel-item {

        .carousel-image {
            border-radius:5px;
            position: relative;
            .carousel-image-description {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                width: 150px;
            }
            .layer {
                border-radius:5px;
                background-color: rgba(102, 102, 102, 0.3);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .layer-hovered {
                background-color: rgba(0, 0, 0, 0.55);
            }
        }

        .disabled-link {
            pointer-events: none
        }
    }
}