.C-Drawer.--lg-size-4.--location-right.isVisible.asset-activity-drawer {
  z-index: 10000;
  overflow-x: auto; /* Enable vertical scrolling */

  .C-GridItem.--xs-size-auto {
    padding-top: 20px;
    padding-bottom: 21px;
  }

  .C-Timeline__Item__Content {
    flex-direction: column;
  }

  .C-GridItem.--xs-size-auto.custom-padding {
    padding-top: 10px;
  }

  .hc-container.hc-pa-normal {
      padding-left: 18px !important;
  }
  
  .activity-action-spinner{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .asset-reports-container {
    width: 100%;
    max-height: 85vh;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .asset-reports-container::-webkit-scrollbar {
    display: none;
  }
}
