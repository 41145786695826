.asset {
    position: relative;
     .result {
        width: 200px;
        height: 240px;
        position:relative;
        overflow:hidden;
        border-radius:5px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);  
        transition: box-shadow 0.3s;
        &:hover {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3)
        }
       
    }

    &__thumbnail {
        flex-grow: 1;
        // height:85% !important;
        width: 200px !important;
        height: 207px;
    }
    &__details {
        // display:flex;
        // margin:0;
        // height: 10%;
        // display: grid;
        // grid-template-columns: 8fr 2fr;
        // grid-template-rows: 1fr;
        // grid-column-gap: 10px;
        // grid-row-gap: 0px; 

        & > div {
            // display: flex;
            // flex-direction: row;
            // align-items: center;
        }

        // .name { 
        //     grid-area: 1 / 1 / 2 / 2;
        //     padding-left: 12px;
        //     justify-content: flex-start;
        // }
        // .actions { 
        //     grid-area: 1 / 2 / 2 / 3; 
        //     justify-content: center;
        // } 
    }
   
    &__checkbox {
        position:absolute;
        top:0px;
        right:0px;
        z-index: 2;
        cursor: pointer;
        & > label {
            cursor:pointer;
        }
    }

    &__reportflag {
        position:absolute;
        top:0px;
        left:0px;
        z-index: 2;
    }
}

.line-limit-2 {
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   font-weight:bold;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
   word-break: break-all;
}

.name {
    color: #333333;
}