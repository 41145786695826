.lightbox-container {
    position: relative;

    .download-file-button {
        position: absolute;
        right: 42px;
        top: 12px;
        background: #fcfcfc;
        border: 2px solid gainsboro;
    }

    .lightbox-chip {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0%);
        background: #fcfcfc;
        font-weight: bold;
    }
}