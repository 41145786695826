.drawer {
    border-radius:5px;
    margin: 12px;
    display: flex;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);  
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3)
    }
}

.file-drawer {
    .C-Button {
        color: black
    }
}
.drawer-expandable-section {
    display: flex;
}

.filedrawer-files-table {
    align-items: center;
    background-color: #F9F9F9;
    &:hover {
        background-color: #efefef;
    }
}

.filedrawer-expandable-container {
    width: 100%;
    .C-ExpandableSection__Content {
        animation-duration: 0s !important;
    }
}

.ellipsis {
    padding: 0 0 0 20px;
}


.drawer-checkbox {
    margin: 5px 6px 5px!important;
    padding: 5px 0 5px!important;
    font-weight: bold;
}

.drawer-file-checkbox {
    margin: 5px 6px 5px 20px!important;
    padding: 5px 0 5px 20px!important;
    word-break: break-all;
}

.drawer-avatar {
    margin-top: 10px;
}
