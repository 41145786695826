.edit-asset-user-model {
   .C-Modal__scrollContainer{
        max-width: 40em;
        min-width: 40em ;
        min-height: 40em;
        max-height: 40em;
        display: flex;
        flex-direction: column;
        outline: none;
     }
     .C-Modal__container {
         margin: 0;
         outline: none;
     }
    
    .edit-asset-user-container {
        display: flex;
        flex-grow: 1;
        outline: none;
    }

    .C-Form {
        flex-grow: 1;
        outline: none;
    }

    .C-FormField__Label {
        font-weight: normal;
    }

    .bottom-buttons{
        outline: none;
    }

    .asset-user-list {
        min-height: 20em;
        max-height: 20em;
        padding-right:20px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        outline: none;
    }

    .asset-user-item {
        min-height: 5em;
        outline: none;
    }

    // changes font size of placeholder form field text
    & ::placeholder {
        font-size: 14px !important;
        font-weight:lighter;
    }

    .spinner {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}