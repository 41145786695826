.fileList {
    border:1px solid #CCC;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 192px;
}

.modal-parent-container {
    max-width: 1000px;
    margin: auto;
    z-index: 100;
}

.C-Modal__scrollContainer::-webkit-scrollbar {
    display: none;
}

.table__filename {
    padding-left:20px;
}

.submitButton {
    width:100px;
}

.C-FormField__FieldInfo {
    margin-top: 0px;
}

.trash-icon {
    color: black;
}