.create-and-add-to-collection-modal {
    .C-Modal__container{
        max-width: 60em;
        min-width: 60em;
        min-height: 40em;
        display: flex;
        flex-direction: column;
    }

    .C-FormField--Textarea {
        min-height: 12em;
    }


    .C-FormRadio__Option {
        margin-bottom: 16px;
    }

    .C-FormField--Input {
        min-height: 45px;
    }

    .C-Button__innerContent {
        text-transform: none;
        font-size: 14px;
        font-weight: normal;
    }

    .add-asset-button {
        min-width: 9em;
    }

    .border-not-select {
        border-color: grey;
        border-width: 1px;
    }

    .border-not-select:hover {
        .icon {
            color: rgb(65, 64, 64);
        }
        border-color: rgb(65, 64, 64);
    }

    .border-select:hover {
        .icon {
            color: rgb(107, 138, 223);
        }
        border-color: rgb(107, 138, 223);
    }

    .border-select {
        border-color: rgb(34, 93, 219);
        border-width: 2px;
    }

    .collection-select-box {
        border-style: solid;
        border-radius: 5px;
        min-height: 70px;
        display:flex;
        align-items: center;
    }

    .asset-list {
        max-height: 27em;
    }

    .spinner {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .C-FormField__FieldInfo {
        min-height: 0px!important;
        height: 0px!important;
        margin: 0px!important;
    }

    .C-FormField__Label {
        font-weight: bold;
    }

    // changes font size of placeholder form field text
    & ::placeholder {
        font-size: 14px !important;
        font-weight:lighter;
    }
}
.margin-padding-create-collection {
    padding: 12px 0 0 0 !important;
}
