.create-collection-modal {
    & .table__filename {
        padding-left:20px;
    }

    & .assetList {
        border:1px solid #CCC;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 192px;
    }

    & .C-Modal__container{
        max-width: 37em;
        min-width: 20em;
    }
    
    & .C-FormField__Label {
        font-weight: bold;
    }

    // removes the bottom fieldInfo field from the Form.Field component
    & .C-FormField__FieldInfo {
        min-height: 0px!important;
        height: 0px!important;
        margin: 0px!important;
    }    
    
    & .submitButton {
        width:auto;
    }

    & .create-collection-container {
        max-width: 37em;
        min-width: 20em;
        padding: 12px;
    }

    // changes font size of placeholder form field text
    & ::placeholder {
        font-size: 14px!important;
        padding-top: 4px!important;
    }

    & :-ms-input-placeholder {
        font-size: 14px!important;
        padding-top: 4px!important;
    }

    & ::-ms-input-placeholder {
        font-size: 14px!important;
        padding-top: 4px!important;
    }
}



