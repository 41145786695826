.header-main-link {
    position: relative;
    top: 5px;
 }
 
.header {
    height: 35px;
    width: 200px;
    background-image: url('./header.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 20px;
}

.user-name {
    font-weight: bold;
}

