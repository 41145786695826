.centered-feed-content {
// Temporary fix for our design needs. Will change w/ canvas at later date.
  @media only screen and (max-width: 1250px) {
    width: 696px !important;
  }

  @media only screen and (min-width: 1250px) {
    width: 1052px !important;
  }

  &.expanded {
    @media only screen and (min-width: 1740px) {
      width: 1408px !important;
    }

    @media only screen and (min-width: 1920px) {
      width: 1764px !important;
    }
  }

  margin: auto !important;

  .C-Divider, .--horizontal, .C-Divider__line {
    display: none;
    visibility: hidden;
  }
}

.view-all-link {
    color:#4A7ADD
}

.active-sort {
    text-decoration: underline;
    font-weight: bold
}

.C-Tabs__Button.isSelected {
  border-bottom: 4px solid #3e72db;
  color: #3e72db !important;
  padding-bottom: 0;
}

.collection-list {
  height: 60vh;
  overflow-y: scroll;
}

.collection-container-width-control {
  @media only screen and (min-width: 1300px) {
    width: 100%;
    padding: 0 calc((100% - 1030px) / 2);
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
    padding: 0 calc(100% / 5);
  }
}
