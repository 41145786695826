.trash-icon {
    color: black;
}

.edit-collection-assetList {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
}

.modal-parent-container {
    max-width: 1000px;
    margin: auto;
    z-index: 100;
}

.button-container {
    margin-top: 1em;
    margin-bottom: -1em;
}

.submit-button {
    margin-left: 1em;
    margin-right: 1em;
}

.bold-collection-text {
    font-weight: bold;
    margin-right:8px;
}
