.tag-value-text {
    font-weight: bold;
}

.hyperlink {
    color: blue;
    text-decoration: underline;
  }

.tag-divider {
    margin-bottom: 1px !important;
}
  
 